@import '~app/mixins';

.checkout {
  width: 1050px;
  color: $color-grey;

  .header {
    font-size: 16px;
    padding-bottom: 8px;
    text-align: center;
    font-weight: bold;
    border-bottom: 2px solid #ddd;
    width: 85%;
    margin: 0 auto 15px;
  }

  .body {
    display: flex;
    justify-content: space-between;

    .left {
      img {
        width: 500px;
        box-shadow: 2px 2px 5px grey;

        + img {
          margin-top: 15px;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .content {
        width: 516px;
        font-size: 14px;
        padding: 15px 30px 25px;
        box-shadow: 5px 3px 44px rgba(0, 0, 0, 0.16);
        background-color: white;

        .important {
          color: $color-red;
          text-align: center;

          :first-child {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: bold;

            svg {
              height: 30px;
              width: 30px;
              fill: $color-red;
              margin-right: 8px;
            }
          }

          :last-child {
            margin-top: 5px;
          }
        }

        .checklist {
          margin: 25px 0;

          > div {
            margin-top: 12px;
            cursor: pointer;

            label span {
              font-weight: bold;
              font-size: 14px;
            }
          }
        }

        .instructions {
          font-size: 14px;
          line-height: 16px;

          :last-child {
            margin-top: 15px;
          }
        }
      }

      .message {
        font-weight: bold;
        text-align: center;
        color: $color-red;
        margin-top: 15px;

        a {
          text-decoration: underline;
          color: blue;
        }
      }

      .buttons {
        text-align: right;
        margin-top: 20px;
      }
    }
  }
}
